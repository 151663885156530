import React from 'react';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Location = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // URL for the iframe, pointing to a specific location on a map service (e.g., Google Maps)
    const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2171523812513!2d78.38486537369094!3d17.449317601021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9159d4e222db%3A0x1732c6e0c95aaf27!2sNative%20Techies!5e0!3m2!1sen!2sin!4v1712996734203!5m2!1sen!2sin";

    return (
        <div>
           
            {/* Embed an iframe with the map URL */}
            <iframe
                src={mapUrl}
                
                height="200" 
                style={{ borderRadius: "10px",
                width:isMobile?'95%':'97%',
            marginLeft:isMobile?'10px':'20px' ,marginTop:'20px'}} // Remove border
                allowFullScreen="full" // Allow full-screen view
                loading="lazy"
                title="Location Map"
                borderRadius='20px'
            ></iframe>
        </div>
    );
};

export default Location;
