import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const drawerWidth = 240;

function HomeSection(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
      <Link to="/" style={{ textDecoration: "none" }}>
        <img src="/Images/Logo.jpg" alt="Logo" style={{ height: "50px" }} />
        </Link>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link to="/" style={{ textDecoration: "none" }}>
            <ListItemButton
              sx={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>HOME</span>}
              />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/about" style={{ textDecoration: "none" }}>
            <ListItemButton
              sx={{ textAlign: "center", color: "black", fontWeight: "bold" }}
            >
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>ABOUT US</span>}
              />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/service" style={{ textDecoration: "none" }}>
            <ListItemButton
              sx={{ textAlign: "center", color: "black", fontWeight: "bold" }}
            >
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>SERVICES</span>}
              />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <ListItemButton
              sx={{ textAlign: "center", color: "black", fontWeight: "bold" }}
            >
              <ListItemText
                primary={<span style={{ fontWeight: "bold" }}>CONTACT US</span>}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ bgcolor: "#555555", height: 80 }}>
        <Toolbar>
          <IconButton
            color="inherit" // MenuIcon color to inherit from AppBar
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              
              display: { sm: "none" }, // Hide on larger screens, only show on mobile
               // Increase the font size of the MenuIcon
              padding: "2px", // Adjust padding for better alignment
              marginTop:'25px',
              marginLeft: "1px", // Adjust margin to align properly
              alignSelf: "center", // Aligns the button properly in the AppBar
            }}
          >
            <MenuIcon sx={{ fontSize: '2rem' }}/>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="/Images/Logo.png"
              alt="Logo"
              style={{ height: "80px", marginLeft: "20px" }}
            />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Link to="/">
              <Button
                sx={{
                  color: "#FFFFFF",
                  marginRight: "80px",
                  fontWeight: "bold",
                }}
              >
                Home
              </Button>
            </Link>
            <Link to="/about">
              <Button
                sx={{
                  color: "#FFFFFF",
                  marginRight: "80px",
                  fontWeight: "bold",
                }}
              >
                About Us
              </Button>
            </Link>
            <Link to="/service">
              <Button
                sx={{
                  color: "#FFFFFF",
                  marginRight: "80px",
                  fontWeight: "bold",
                }}
              >
                Services
              </Button>
            </Link>

            <Link to="contact">
              <Button
                sx={{
                  color: "#FFFFFF",
                  marginRight: "80px",
                  fontWeight: "bold",
                }}
              >
                Contact Us
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        {/* Your content here */}
      </Box>
    </Box>
  );
}

HomeSection.propTypes = {
  window: PropTypes.func,
};

export default HomeSection;
