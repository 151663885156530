import React from 'react'

import ImageSection from './Images'
import AboutUs from './About'
import Ourmission from './Ourmission'
import Services from './Services'
import ContactUs from './ContactUs'

const HomePage = () => {

  return (
    <div>
        
        <ImageSection/>
        <AboutUs />
        <Ourmission/>
        <Services/>
        <ContactUs/>
    </div>
  )
}

export default HomePage