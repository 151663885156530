import React from "react";
import { Helmet } from "react-helmet";


import Location from "./Location";
import ContactUs from "../HomePage/ContactUs";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ContactUsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <div style={{marginTop:isMobile?'':'-25px'}}>
<Helmet>
  <meta charSet="utf-8" />
  <title>NativeTechies | ContactUs </title>

  </Helmet>
   
  <ContactUs/>
  <Location/>
  </div>
);
};
export default ContactUsPage;
