import React, { useState } from "react";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    image: "/Images/MuelSoft.jpg",
    // name: "MuleSoft",
    Description:
      "Mulesoft is the #1 integration platform for SOA, SaaS, and APIs in the world. By utilising an API-led strategy to connect devices, data, and applications both on-premises and in the cloud.",
  },
  {
    image: "/Images/PowerBI.jpg",
    // name: "Power BI",
    Description:
      "Stay agile and build solutions that move your business forward with the only set of low-code tools your teams need. Power Platform is built from the ground up to work together.",
  },
  {
    image: "/Images/AWS.jpg",
    // name: "AWS DEVOPS",
    Description:
      "Utilizing AWS DevOps, we streamline our software development processes, seamlessly integrating development and operations to achieve agility and efficiency.",
  },
  {
    image: "/Images/Testing.jpg",
    // name: "MANUAL & AUTOMATION TESTING",
    Description:
      "Combining manual and automation testing methodologies, we ensure comprehensive software quality assurance for our clients.",
  },
  {
    image: "/Images/Python.jpg",
    // name: "Python",
    Description:
      "Python serves as the cornerstone of our development arsenal, empowering us to craft versatile and efficient solutions for our clients. With its clean syntax and extensive libraries, we leverage Python to create robust applications.",
  },
  {
    image: "/Images/FullStack.jpg",
    // name: "JavaFullStack",
    Description:
      "Our company excels in Java full-stack development, harnessing the language's robustness to craft end-to-end solutions for diverse business needs. Leveraging Java's versatility.",
  },
  {
    image: "/Images/CyberSecurity.jpg",
    // name: "CyberSecurity",
    Description:
      "At our company, cybersecurity is paramount. We employ cutting-edge techniques and technologies to safeguard our clients' digital assets from evolving threats.",
  },
  // Add more data objects as needed
];

const ServicesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isHovered, setIsHovered] = useState(false);


 

  const styles = {
    servicesPage: {
      position: "relative",
      width: "100%",
      height: isMobile?'30vh':"100vh",
      overflow: "hidden",
    },
    servicesBackground: {
      width: "100%",
      height: "100%",
      backgroundImage: "url('/Images/services.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "0",
      left: "0",
    },
  };

  return (
    <div style={{ marginTop: "-32px" }}>
       <Helmet>
  <meta charSet="utf-8" />
  <title>NativeTechies | Services </title>

  </Helmet>
      <div style={styles.servicesPage}>
        <div style={styles.servicesBackground}></div>
      </div>
      <Box
        sx={{
          bgcolor: "#003262",
          marginTop:isMobile?'px':'',
          padding: "50px",
        
        }}
      >
        <Typography
          color="white"
          sx={{
            textAlign: "center",
            padding: isMobile?'':"15px",
            marginLeft: "14px",
            fontWeight: "bolder",
            fontSize: "34px",
          }}
        >
          We Provide Best Quality Services
        </Typography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: isMobile ? "" : "10px",
            marginTop: isMobile ? "20px" : "0px",
          }}
        >
          {data.map((service, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{
                  height: 450,
                  marginTop: "20px",
                  borderRadius: "10px",
                  position: "relative",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <CardMedia
                  component="img"
                 
                  image={service.image}
                  alt={service.name}
                  sx={{
                    height: isMobile ? '160px' : '150px',
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
                <CardContent>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "22px",
                      "&:hover": {
                        cursor: "pointer",
                        color: "#0070FF",
                      },
                    }}
                  >
                    {service.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {service.Description}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    padding: "10px",
                    cursor: "pointer",
                    left: 0,
                    width: "100%",
                    bgcolor: "white",
                    color: "black",
                    textAlign: "center",
                    overflow: "hidden",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      left: "-100%", // Start from the left
                      top: 0,
                      width: "100%",
                      height: "100%",
                      background: "#0070FF",
                      transition: "left 1s",
                    },
                    "&:hover::before": {
                      left: 0,
                    },
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    Read More
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon
                      color={isHovered ? "white" : "black"}
                      icon={faAngleDoubleRight}
                    />
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default ServicesPage;
