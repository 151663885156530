import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

const Ourmission = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        height: isSmallScreen ? 'auto' : '2.5in',
        width: isSmallScreen ? '100%' : 'calc(100% - 300px)',
        margin: isSmallScreen ? '20px 0' : '20px 150px',
        backgroundColor: '#004A6D', // Background color
        padding: '20px',
      }}
    >
      <Box sx={{ flex: 1, borderRight: isSmallScreen ? 'none' : '0',  position: 'relative'}}>
        <Typography variant="h3" sx={{ fontFamily: 'Poppins, Sans-serif', fontWeight: 'bold', fontSize: '24px', letterSpacing: '1.5px', lineHeight: '1.5', wordSpacing: '2px', zIndex: 1, position: 'relative', color: 'white', paddingBottom: '10px' }}>
          Our Mission
          <span
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              height: '2px',
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
            }}
          ></span>
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'justify', color: '#FFFFFF', fontFamily: 'Poppins, Sans-serif', fontSize: '16px', fontWeight: 400, paddingTop: '10px', paddingRight: '20px', paddingBottom: '10px', paddingLeft: '0', lineHeight: '1.6', letterSpacing: '0.5px', wordSpacing: '1px' }}>
          At Native Techies Technologies, our mission is to catalyse innovation and transcend boundaries,
          revolutionizing industries and individuals alike.
          Through cutting-edge software solutions and unwavering commitment,
          we empower businesses to chart new territories of success.
        </Typography>
      </Box>
      <Box sx={{ flex: 1, paddingLeft: isSmallScreen ? '0' : '20px', position: 'relative' }}>
        <Typography variant="h3" sx={{ fontFamily: 'Poppins, Sans-serif', fontWeight: 'bold', fontSize: '24px', letterSpacing: '1.5px', lineHeight: '1.5', wordSpacing: '2px', zIndex: 1, position: 'relative', color: 'white', paddingLeft: isSmallScreen?'0px': '20px', paddingBottom: '10px' }}>
          Our Vision
          <span
            style={{
              position: 'absolute',
              left: isSmallScreen?'3px':'20px',
              bottom: 0,
              height: '2px',
              width: 'calc(100% - 20px)',
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
            }}
          ></span>
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'justify', color: '#FFFFFF', fontFamily: 'Poppins, Sans-serif', fontSize: '16px', fontWeight: 400, paddingTop: '10px', paddingRight: '0', paddingBottom: '10px', paddingLeft: isSmallScreen?'':'20px', lineHeight: '1.6', letterSpacing: '0.5px', wordSpacing: '1px' }}>
          Our vision is a world where innovation knows no limits – a realm where possibilities are transformed
          into reality through the seamless synergy of technology and creativity.
          We aspire to be the driving force that reshapes industries and empowers dreamers to achieve the extraordinary.
        </Typography>
      </Box>
    </Box>
  );
};

export default Ourmission;