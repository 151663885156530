import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function ImageSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const imageStyles = {
    width: isMobile ? '100%' : '100%', // Use the entire width in both mobile and desktop views
    height: isMobile ? '50vh' : '120vh', // Adjust the height based on the view
    backgroundImage: `url(${process.env.PUBLIC_URL}/Images/Image_6.jpg)`,
    backgroundSize: 'cover',
    margin: isMobile ? '0' : '-40px 0px 0px 0px', // No margin in mobile view, negative margin in desktop view
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: '0px', // Keep the border radius consistent
    transition: 'transform 0.5s', // Add a transition for smooth zoom effect
    position: 'relative', // Positioning for absolute text
  };
  
  

const hoverStyles = {
  transform: 'scale(1.0)', // Zoom in effect
};

const textStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: isMobile?'1.7em':'3em',
  fontWeight: 600,
  lineHeight: isMobile?'35px':'72px',
  color: '#FFFFFF',
  textAlign: 'left',
  position: 'absolute',
  top: isMobile?'50%':'35%',
  left: isMobile?'40px':'100px',
  transform: 'translateY(-50%)',
  backgroundImage: 'linear-gradient(90deg, #0087BD, #F5FFFA, #0087BD)',
  letterSpacing: '5px',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',

  backgroundSize: '80%',
  animation: 'shine 15s linear infinite', // Shine with a linear infinite animation
  WebkitFontSmoothing: 'antialiased',
};


const subTextStyle = {
  fontFamily: 'Fira Sans',
  fontSize: isMobile?'20px':'24px',
  fontWeight: 400,
  lineHeight: '32px',
  color: '#FFFFFF',
  textAlign: 'left',
  position: 'absolute',
  top: isMobile?'70%':'55%', // Adjust this value to position the subtext below the main text
  left: isMobile?'45px':'100px', // Adjust this value to align the subtext to the left
  transform: 'translateY(-50%)', // Center vertically
  animation: 'fadeInUp 10s forwards', // Apply animation
};

const logoStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '100px',
  height: 'auto',
};

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      style={{
        ...(imageStyles),
        ...(isHovered && hoverStyles),
      
        position: 'relative',
        marginTop:'-40px'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
       {isMobile && <img src={`${process.env.PUBLIC_URL}/Images/Logo.png`} alt="Logo" style={logoStyle} />}
      <div style={textStyle}>
        Strategy & Technology <br /> Consultants to the world
      </div>
      <div style={subTextStyle}>
        Where imagination meets innovation We unlock the power of technology <br /> to transform your brand.
      </div>
    </div>
  );
}

export default ImageSection;
