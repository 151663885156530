import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserRouter, RouterProvider,  } from 'react-router-dom';

import Footer from './Components/Footer';
import HomePage from './Components/HomePage/HomePage';
import ServicesPage from './Components/Services';
import { Outlet } from 'react-router-dom';
import HomeSection from './Components/Home';
import ContactUs from './Components/ContactUs/ContactUs';
import Aboutus from './Components/AboutUs/AboutUs';

const Layout = () => (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>NativeTechies</title>
        {/* <link rel="canonical" href="http://iqguru.co.in/" /> */}
    </Helmet>
    <div>
            <HomeSection />
            <Outlet />
            <Footer />
        </div></>
);

const App = () => {
    const appRouter = createBrowserRouter([
        {
            path: '/',
            element: <Layout />, 
            children: [
                {
                    path: '/',
                    element: <HomePage />,
                },
                {
                    path: '/service',
                    element: <ServicesPage />,
                },
                {
                  path:'/contact',
                  element:<ContactUs/>
                },
                {
                    path:'/about',
                    element:<Aboutus/>
                  }

            ],
        },
    ]);

    return (
        <RouterProvider router={appRouter} />
    );
};

export default App;
