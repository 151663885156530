import React from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";


function Aboutus() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



  const ImageContainer = {
    position: "relative",
    height: isMobile ? "30vh" : "3in", // Set height differently for mobile view
    marginTop: "-35px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `
      linear-gradient(
        rgba(0, 0, 128, 0.6),
        rgba(255, 255, 255, 0.3)
      ),
      url('/Images/About.jpeg')
    `,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // This ensures the background image does not repeat
  };

  // Define a new function for the h2 heading styles
  const getH2Style = {
    fontSize: isMobile ? "2.5rem" : "42px",
    color: "#fff",
    margin: "0",
    padding: "0",
    textAlign: "center", // Align text center on both mobile and desktop
    zIndex: 2, // Ensure the heading is above the background
    visibility: "visible",
    animationDelay: "0.3s",
    animationName: "fadeInUp",
    fontFamily: '"Kumbh Sans", sans-serif', // Use specified font family
    fontWeight: "bold", // Sets font weight to bold

};


  const AboutContainer = {
    minHeight: "100vh",
    width: "100%",
    backgroundImage: "url('/Images/service-bg-2.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    padding: "20px",
  };

  const InnerContainer = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: isMobile ? "20px" : "10%",
    height: isMobile ? "auto" : "80vh",
    width: "100%",
    paddingTop: isMobile ? "20px" : "0",
  };

  const LeftPart = {
    width: isMobile ? "100%" : "35%",
    height: isMobile ? "200px" : "80vh",
    backgroundImage: "url('/Images/Image_3.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginRight: isMobile ? "0" : "-50px", // Add margin to the right side on desktop view
    marginLeft: isMobile ? "0" : "50px",
    borderRadius: isMobile ? "10%" : "50%",
  };

  const RightPart = {
    width: isMobile ? "100%" : "65%",
    padding: "20px",
  };

  const headingStyle = {
    fontSize: "24px",
    marginBottom: "10px",
    color: "#000080",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 300,
    textAlign: isMobile ? "center" : "left", // Center text on mobile view
  };

  const paragraphStyle = {
    fontSize: "16px",
    fontFamily: "Kumbh, sans-serif",
    color: "#585858",
    lineHeight: isMobile ? "2" : "2",
    paddingBottom: "10px",
    textAlign: isMobile ? "justify" : "left", // Justify text alignment on mobile view
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>NativeTechies | AboutUs </title>

  </Helmet>
      <div style={ImageContainer}>
        <h2 style={getH2Style}>About Us</h2>
      </div>
      <div style={AboutContainer}>
        <div style={InnerContainer}>
          <motion.div
            style={LeftPart}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 120,
            }}
          />
          <div style={RightPart}>
            <h1 style={headingStyle}>ABOUT NATIVETECHIES</h1>
            <p style={paragraphStyle}>
              <span
                style={{ color: "#FF4F00", fontFamily: "Roboto, sans-serif" }}
              >
                NATIVE TECHIES PVT LTD
              </span>
              , we are catalysts of digital transformation, breathing life into
              innovative possibilities through our expertise in low-code
              solutions.
            </p>
            <p style={paragraphStyle}>
              <span
                style={{ color: "#FF4F00", fontFamily: "Roboto, sans-serif" }}
              >
                Our Expertise:&nbsp;
              </span>
              As a globally renowned software consultancy, we specialize in
              low-code platforms that propel businesses towards profound
              transformation. Our proficiency spans across platforms such as
              Power-BI, Mulesoft, AWS DevOPS, Manual & Automation testing,
              MAINFRAMES, python, java full stack and cyber security.
            </p>
            <p style={paragraphStyle}>
              <span
                style={{ color: "#FF4F00", fontFamily: "Roboto, sans-serif" }}
              >
                Transforming Possibilities
              </span>
              : We thrive on transcending limits and redefining what’s
              achievable. By mastering the intricacies of low-code platforms, we
              unlock new dimensions of possibility for our clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Aboutus;
