import React from "react";
import { Box, TextField, Typography, Button, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const ContactUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const formik = useFormik({
        initialValues: {
          name: "",
          contactNumber: "",
          email: "",
          message: "",
        },
        validationSchema: Yup.object({
          name: Yup.string()
            .required("Name is required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
          contactNumber: Yup.string()
            .required("Contact number is required")
            .matches(/^\d{10}$/, "Contact number must be 10 digits"),
          email: Yup.string().email("Invalid email address").required("Email is required"),
          message: Yup.string().required("Message is required"),
        }),
        onSubmit: (values) => {
          // Handle form submission
          console.log(values);
        },
        validateOnChange: true, // Enable real-time validation on change
        validateOnBlur: true, // Enable validation on blur
      });

  return (
    <div>

   
    <Box
    sx={{
      //borderRadius: '15px',
      //margin: '20px',
      padding: isMobile?'10px':'60px',
      display: 'flex',
      flexDirection: isMobile?'column':'row',
      bgcolor:'#F0F8FF'
    }}
  >
    <Box
      sx={{
        width: isMobile?'100%': '50%',
      }}>
      <Typography  variant={isMobile ? 'h5' : 'h3'} gutterBottom style={{ fontWeight: 'bold', color: 'black' }}>Get free business consultation today</Typography>

      <Typography variant="body1" gutterBottom>
      Do you have questions about how Consulting can help your company? Send us an email and we’ll get in touch shortly, or phone between 8:00 and 18:00 Monday to Friday — we would be delighted to speak.
      </Typography>
      <img src="/Images/contact.svg" alt="contact" style={{ width: '100%', maxWidth: '500px', borderRadius: '15px', marginBottom: '0px',marginTop:'10px' }} />
    </Box>
    <Box
      sx={{
        width: isMobile?'100%':'50%',
        marginLeft: isMobile?'':'30px',
        marginTop:'60px'
      }}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          style={{ marginBottom: '10px' }}
          name="name"
        />
        <TextField
          label="Contact Number"
          variant="outlined"
          fullWidth
          value={formik.values.contactNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
          helperText={formik.touched.contactNumber && formik.errors.contactNumber}
          style={{ marginBottom: '10px' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
          }}
          name="contactNumber"
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          style={{ marginBottom: '10px' }}
          name="email"
        />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          style={{ marginBottom: '10px' }}
          name="message"
        />
        <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
          Submit
        </Button>
      </form>
    </Box>
  </Box>
  
  </div>
);
};
export default ContactUs;
